<template>
	<div>
		<header class="header" :class='{"light": lightmode}'>
			<div class="container text-left">
				<h3 class='float-left header-text-item' v-if='election.details !== undefined && election.details.logo.length > 0'>{{election.details.name}}</h3>
				<h3 class='float-left header-text-item' v-if='election.details === undefined || election.details.logo.length == 0'>{{branding.platform_name}}</h3>
				
				<img class='logo-img float-right branded-logo' :src='election.details.logo'> 
					<!--<h1 class='mt-2' v-if='election.details !== undefined'>{{election.details.name || 'AtlasVote'}}</h1>-->
			</div>
		</header>
		<!-- 

			<template>
				<div>
					<header class="header">
						<div class="container">
							<div class="text-white w-100 text-center">
								<h1 class='mt-2 text-white w-100' v-if='election.details !== undefined'>{{election.details.name || 'AtlasVote'}}</h1>
								<h1 class='mt-2 text-white w-100' v-if='election.details === undefined'>AtlasVote</h1>
							</div>
						</div>
					</header>
				</div>
			</template>

		-->
	</div>
</template>

<style scoped lang='scss'>
	.header{
		height: 85px;
		background-color: #000;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
		text-align: center;

		.header-text-item{
			line-height: 65px;
			font-size: 30px;
			margin-bottom: 0px;
			padding-left: 15px;
			color: #DDD;
		}

		&.light{
			background-color: white;

			.header-text-item{
				color: #333;
			}
		}

		.logo-img{
			height: 65px;
		}

		.logo{
			min-width: auto;
			padding-right: 2.2rem;
			padding-left: 0px;
			height: 65px;

			img{
				height: 65px;
			}

			h1{
				color: white;
			}
		}
	}
	.invert-image-color{
		filter: invert(1);
		img{
			filter: brightness(0);
		}
	}
</style>

<script>
	const updateElementClass = (el, stat, name) => el && el.classList[stat ? 'add' : 'remove'](name)
    const updateBodyClass = (stat, name) => updateElementClass(document.body, stat, name)
    
	import { mapState, mapGetters } from 'vuex';
	import store from "@/store/";

	export default {
		name: 'Header',
		components: {
		},
		data: () => {
			return {
				show_switcher: false,
				display_menu: false
			}
		},
		computed: {
			lightmode(){
				return this.branding.lightmode;
			},
			branded_header(){
				return {
					'backgroundColor': this.branding.header_color,
					'color': '#fff'
				}
			},
			...mapGetters(['election', 'branding'])
		},
		methods: {
			close_menu(){
				this.display_menu = false;
			},
			/**
			 * Triggers a window resize event when clicked
			 * for plugins that needs to be redrawn
			 */
			resize: () => {
				// all IE friendly dispatchEvent
				var evt = document.createEvent('UIEvents');
				evt.initUIEvent('resize', true, false, window, 0);
				window.dispatchEvent(evt);
				// modern dispatchEvent way
				// window.dispatchEvent(new Event('resize'));
			}
		}
	}
</script>