<template>
	<footer class=" pb-4" :class='{"light": lightmode}'>
		<div class='container'>
			<span class='mobile-stacked'><br/>{{ branding.platform_name || "AtlasVote" }} &copy; {{year}}<br/><span style='color: #AAA'>By {{ branding.legal_name || "Atlas Web Services"}}</span></span>
			<a class='mobile-stacked float-right footer-link' href='mailto:support@atlasvote.com' v-if='!branding.platform_support'>Contact Support</a>
			<a class='mobile-stacked float-right footer-link' :href='"mailto:"+branding.platform_support' v-if='branding.platform_support'>Contact Support</a>
		</div>
	</footer>
</template>

<style lang='scss'>
	footer{
		width: 100%;
		/*position: absolute;
		bottom: 0;
		left: 0;*/
		background-color: #000;
		z-index: 9999;

		&.light{
			background-color: white;
		}
	}
	.footer-link{
		color: #DC1F26;
		text-decoration: underline
	}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import store from "@/store/";

	
export default {
	name: 'Footer',
	computed: {
		year(){
			var d = new Date();
			return d.getFullYear();
		},
		lightmode(){
			return this.branding.lightmode;
		},
		...mapGetters(['branding'])
	}
}
</script>