<template>
	<main class="main">
		<div class='bg-wrap' :class='{"light": lightmode}'>
			<!-- top navbar-->

			<LoadingPage v-if='loading'/>
			
			<!-- Main section-->
			<section class="main-content primary-box container pt-0 mt-xs-0 pb-0">
				<div class='logo-box mb-5' :style='logo_block' v-if='false'>
					
					&nbsp;

				</div>
				<!-- Page content-->
				<router-view/>
			</section>

			<!-- Page footer-->
		</div>
		<Footer />
	</main>
</template>

<style lang='scss' scoped>
	.main{
		width: 100%;
		background-image: url('/images/sample-bg-3.jpg') !important;
		background-size: cover;
		background-position: center;

		display: flex;
		flex-direction: column;
		min-height: 100vh;

		.bg-wrap{
			width: 100%;
			background-color: rgba(0,0,0,0.8);
			flex-grow: 1;

			&.light{
				background-color: rgba(220,220,220,0.85);
			}
		}
	}
	.main-content{
		margin-top: 120px;
		padding-top: 20px;
	}
	.logo-box{
		height: 100px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: auto 100%;
	}
</style>

<script>
	import { mapGetters } from "vuex";
	import store from "@/store";

	import Vue from 'vue';
	import Header from './Header';
	import Footer from './Footer';
	import LoadingPage from './LoadingPage';
    import ContentWrapper from './ContentWrapper';
    
    Vue.component('ContentWrapper', ContentWrapper);
    
	export default {
		name: 'Layout',
		components: {
			Header,
			Footer,
			LoadingPage
		},
		computed: {
			lightmode(){
				return this.branding.lightmode;
			},
			logo_block(){
				var url = '/images/atlas-logo-sm.png';
				if(this.election.details !== undefined && this.election.details.logo.length > 0){
					url = this.election.details.logo;
				}
				return {
					'backgroundImage': 'url("'+url+'")'
				}
			},
			...mapGetters(['loading', 'election', 'branding'])
		}
	}
</script>